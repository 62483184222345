import { navigate } from "gatsby";
import React from "react";
import { HeroComponent } from "../../components/hero/hero.component";
import "./hero.section.scss";
import { IoIosArrowDown } from "react-icons/io";

export const HeroSection = () => {
  return (
    <>
      <div className="hero-background-container">
        <video
          className="hero-background-1"
          role="presentation"
          crossOrigin="anonymous"
          playsInline={false}
          preload="auto" muted={true}
          loop={true}
          tabIndex={-1}
          typeof={"video/mp4"}
          autoPlay={true}
          poster={"https://raw.githubusercontent.com/Quepler/assets/main/images/quepler-video-poster.webp"}
          src={"https://raw.githubusercontent.com/Quepler/assets/main/videos/quepler-video.mp4"}
          style={
            {
              width: '100%',
              minWidth: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              maxHeight: '700px',
              opacity: 1,
              overflow: "hidden",
              backgroundSize: "cover",
            }
          }
        ></video>
      </div>

      <div className="hero-background-2"></div>
      <div className="hero-section">
        <HeroComponent />
      </div>
      <div className="hero-arrow-down-container">
        <IoIosArrowDown size={50} className="hero-arrow-down" onClick={() => { navigate("/#servicios") }} />
      </div>
    </>
  );
};
