import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./about-of.component.scss";

export const AboutOfComponent = () => {
  return (
    <div className="aboutOf-container">
      <div className="text-content-container">
        <div className="text-content">
          <h2 className="aboutOf-title">DESCUBRA<br/>QUEPLER<br/>TECHNOLOGY</h2>
          
          <div className="title-line"></div>
          <p className="description">
            Somos una empresa que provee, desarrolla e instala soluciones tecnológicas prácticas, con productos y servicios innovadores, realizando un trabajo justo y de calidad, generando un excelente ambiente laboral a nuestros colaboradores, brindando resultados definitivos, garantizando así la confianza y satisfacción de nuestros clientes.
          </p>
        </div>
      </div>
      <div className="image-content-container">
          <StaticImage
              className="image-aboutOf"
              src="../../assets/images/about-of.png"
              alt=""
              placeholder="tracedSVG"
          />
      </div>
    </div >
  );
};
