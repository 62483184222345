import React from "react";
import { ClientsComponent } from "../../components/clients/clients.component";
import "./clients.section.scss";

export const ClientsSection = () => {
  return (
    <>
      <div className="clients-background"></div>
      <div className="main-section clients-section">
        <h1 className="clients-title">CLIENTES</h1>
        <div className="title-line"></div>
        <ClientsComponent />
      </div>
    </>
  );
};
