import { graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
import { ProductCardComponent } from "../../components/product-card/product-card.component";
import "./our-products.section.scss";

export const OurProductsSection = () => {

  const allProductsData = useStaticQuery(
    graphql`
      query getAllProductsData {
        allProductsJson {
          edges {
            node {
              id
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: WEBP
                    pngOptions: {compressionSpeed: 53}
                    transformOptions: {fit: CONTAIN, cropFocus: CENTER}
                  )
                }
              }
              whatsappMessage
            }
          }
        }
      }    
    `
  );

  const getAllProductsCads = (allProductData: any) => {
    const ProductsArray: any[] = [];
    allProductData.allProductsJson.edges.forEach(
      (poroductData: any, index: any) => {
        ProductsArray.push(
          <ProductCardComponent key={poroductData.node.id} cardData={poroductData.node} />
        );
      }
    );
    return ProductsArray;
  }

  return (
    <>
      <div className="products">
        <h2 className="products-title">PRODUCTOS</h2>
        <div className="title-line"></div>
        <div className="products-cards">
          {
            getAllProductsCads(allProductsData)
          }
        </div>

        <div className="products-buttons">
          <button className="button-more" onClick={() => { navigate("/blog/catalogo-de-productos-y-servicios") }}>CONOCER MÁS</button>
        </div>

      </div>
    </>
  );
};
