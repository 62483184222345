import React from 'react';
import "./social-networks.component.scss";
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrYoutube } from "react-icons/gr";

const SocialNetworksComponent = () => (
  <ul className="social-networks">
    <li className="social-network">
      <a className="social-network-link" href="https://www.fb.com/quepler.ec" target="_blank">
        <GrFacebookOption size="36" className="social-network-icon" />
        <span className="social-network-name">Facebook</span>
      </a>
    </li>
    <li className="social-network">
      <a className="social-network-link" href="https://www.instagram.com/quepler.ec" target="_blank">
        <GrInstagram size="36" className="social-network-icon" />
        <span className="social-network-name">Instagram</span>
      </a>
    </li>
    <li className="social-network">
      <a className="social-network-link" href="https://www.linkedin.com/in/quepler-ecuador-152845248/" target="_blank">
        <GrLinkedinOption size="36" className="social-network-icon" />
        <span className="social-network-name">Linkedin</span>
      </a>
    </li>
    <li className="social-network">
      <a className="social-network-link" href="https://www.youtube.com/channel/UC2LL0u5te8BrPdfXcpdZwVw" target="_blank">
        <GrYoutube size="36" className="social-network-icon" />
        <span className="social-network-name">Youtube</span>
      </a>
    </li>
  </ul>
);

export default SocialNetworksComponent;