import React from "react";
import { AboutOfComponent } from "../../components/about-of/about-of.component";
import "./about-of.section.scss";

export const AboutOfSection = () => {
  return (<>
    <div className="about-of-section">
      <AboutOfComponent />
    </div>
    <div className="rectangle-separator"></div>
  </>);
};
