import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./product-card.component.scss";
import { QueplerInfoData } from "../../config/site.config";

export const ProductCardComponent = ({ cardData }: { cardData: any }) => {
  const generateDescription = (descriptionData: any) => {
    const listDescription: any[] = [];
    descriptionData.forEach(
      (description: any, index: any) => {
        listDescription.push(
          <p key={"p-" + index}>{description} <br /></p>
        );
      }
    );
    return listDescription;
  }

  return (
    <div className="product-card">
      <div className="product-card-container">

        <GatsbyImage className="product-card-image" image={cardData.image.childImageSharp.gatsbyImageData} alt={""} />

        <div className="product-card-content">
          <div className="product-card-title">
            <h2 className="title"><b>{cardData.title}</b></h2>
          </div>
          <div className="product-card-description">
            {
              generateDescription(cardData.description)
            }
          </div>
        </div>

        <div className="product-card-buttons">
          <button className="button-cotizar" onClick={() => { window.open(QueplerInfoData.WhatsappLink + cardData.whatsappMessage, '_blank') }}>COTIZAR</button>
        </div>
      </div>
    </div>
  );
};
