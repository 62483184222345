import React from "react";
import { QueplerInfoData } from "../../config/site.config";
import "./hero.component.scss";

export const HeroComponent = () => {

  return (
    <>
      <div className="hero-container">

        <div className="hero-description-container">

          <div className="hero-description">
            <h1>DESARROLLO, INSTALACIÓN Y <br />MANTENIMIENTO DE<br /> TECNOLOGÍA ELÉCTRICA Y<br /> ELECTRÓNICA</h1>

            <div className="hero-buttons">
              <button className="button-cotizar-hero" onClick={() => {window.open(QueplerInfoData.WhatsappLink + "Estuve navegando en su página web www.quepler.com y quisiera mas información.",'_blank')}}>COTIZAR</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
