import React from "react";
import { QueplerInfoData } from "../../config/site.config";
import "./contact.section.scss";
import { Mail, Phone } from "react-feather";
import { ImWhatsapp } from "react-icons/im";

export const ContactSection = () => {
    return (
        <div className="contact-section-container">
            <h1 className="contact-title">CONTACTO</h1>
            <div className="title-line"></div>
            <div className="contact-section">
                <div className="contact-container">
                    <h1>INFORMACIÓN DE CONTACTO</h1>
                    <br />
                    <br />

                    <div className="contact-items-container">

                        <div className="item">
                            <Mail className="icons" size={45} color={"#0000ff"} />
                            <h2>Correo</h2>
                            <p>{QueplerInfoData.email}</p>
                        </div>

                        <div className="item">
                            <Phone className="icons" size={45} color={"#1E2D3B"} />
                            <h2>Teléfono</h2>
                            <p>{QueplerInfoData.movil}</p>
                        </div>

                        <div className="item">
                            <ImWhatsapp className="icons" size={45} color={"#00bb2d"} />
                            <h2>Whatsapp</h2>
                            <p>{QueplerInfoData.whatsapp}</p>
                        </div>
                    </div>
                </div>

                <div className="contact-map">
                    <h1>DONDE ESTAMOS UBICADOS</h1>
                    <br />
                    <br />
                    <div className="map">
                        <iframe
                            title="Ubicación de Quepler en el mapa de google"
                            className="map-iframe"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.4367264997883!2d-78.55909987077183!3d-0.3428107589035227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d5a3f3419acbeb%3A0x9f299c10bcce8db1!2sQUEPLER!5e0!3m2!1ses-419!2sec!4v1658957412505!5m2!1ses-419!2sec"
                            style={{ border: 0 }}
                            allowFullScreen={false}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        >
                        </iframe>
                    </div>
                </div>
            </div>
            <br /><br />
        </div>
    );
};
