import { graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
import { ServiceCardComponent } from "../../components/service-card/service-card.component";
import "./our-services.section.scss";

export const OurServicesSection = () => {

  const allServicesData = useStaticQuery(
    graphql`
      query getAllServicesData {
        allServicesJson {
          edges {
            node {
              id
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: WEBP
                    pngOptions: {compressionSpeed: 53}
                    transformOptions: {fit: CONTAIN, cropFocus: CENTER}
                  )
                }
              }
              whatsappMessage
            }
          }
        }
      }    
    `
  );

  const getAllServicesCads = (allServiceData: any) => {
    const servicesArray: any[] = [];
    allServiceData.allServicesJson.edges.forEach(
      (serviceData: any, index: any) => {
        servicesArray.push(
          <ServiceCardComponent key={serviceData.node.id} cardData={serviceData.node} />
        );
      }
    );
    return servicesArray;
  }

  return (
    <>
      <div className="services">
        <h1 className="services-title">SERVICIOS</h1>
        <div className="title-line"></div>
        <div className="services-cards">
          {
            getAllServicesCads(allServicesData)
          }
        </div>
        <div className="services-buttons">
          <button className="button-more" onClick={() => { navigate("/blog/catalogo-de-productos-y-servicios") }}>CONOCER MÁS</button>
        </div>
      </div>
    </>
  );
};
