import React from "react";
import SocialNetworksComponent from "../../components/social-networks/social-networks.component";
import "./social-networks.section.scss";

export const SocialNetworkSection = () => {
  return (
    <>
      <div className="social-network-container">
        <h1 className="social-network-title">REDES SOCIALES</h1>
        <div className="title-line"></div>
        <SocialNetworksComponent />
      </div>
    </>
  );
};
