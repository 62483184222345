import * as React from "react"
import "../styles/styles.scss"
import { graphql } from "gatsby";
import { MainLayout } from "../layouts/main/main.layout";
import { HeroSection } from "../sections/hero/hero.section";
import { AboutOfSection } from "../sections/about-of/about-of.section";
import { OurProductsSection } from "../sections/our-products/our-products.section";
import { OurServicesSection } from "../sections/our-services/our-services.section";
import { ContactSection } from "../sections/contact/contact.section";
import { SocialNetworkSection } from "../sections/social-networks/social-networks.section";
import { ClientsSection } from "../sections/clients/clients.section";
import { SeoComponent } from "../components/seo/seo.component";

export const Head = ({data}) => {
  const { siteMetadata } = data.site;
  const pageData = {
    title: "Quepler Technology | Desarrollo, Instalación, Mantenimiento y Reparación de Tecnología Eléctrica y Electrónica - Quito, Ecuador",
    siteUrl: siteMetadata.siteUrl,
    url: siteMetadata.url,
    author: siteMetadata.author,
    lang: siteMetadata.lang,
    charSet: siteMetadata.charSet,
    image: siteMetadata.image,
    description: siteMetadata.description,
    twitterUsername: siteMetadata.twitterUsername,
    menuLinks: siteMetadata.menuLinks
  }

  return (
    <>
      <meta name="resource-type" content="Homepage"></meta>
      <SeoComponent {...pageData}/>
    </>
  );
};

const QueplerPage = ({data}) => {
    // const siteMetaData = useStaticQuery(
    //     graphql`
    //   query SiteMetaData {
    //     site {
    //       siteMetadata {
    //         author
    //         description
    //         image
    //         menuLinks {
    //           link
    //           name
    //         }
    //         siteUrl
    //         title
    //         url
    //         lang
    //         charSet
    //       }
    //     }
    //   }        
    // `
    // )

  return (
    <>
      <MainLayout siteMetaData = {data.site.siteMetadata} path="main">
        <section id="home" className="hero-main-section">
          <HeroSection {...data.site.siteMetadata}/>
        </section>
        <section id="servicios" className="main-section">
          <OurServicesSection />
        </section>
        <section id="acerca-de" className="main-section">
          <AboutOfSection />
        </section>
        <section id="productos" className="product-section">
          <OurProductsSection />
        </section>
        <section id="clientes" className="main-section">
          <ClientsSection />
        </section>
        <section id="contacto" className="main-section">
          <ContactSection />
        </section>
        <section id="redes-sociales">
          <SocialNetworkSection />
        </section>
      </MainLayout>
    </>
  )
}

export default QueplerPage;

export const query = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        author
        description
        image
        menuLinks {
          link
          name
        }
        siteUrl
        title
        url
        lang
        charSet
      }
    }
  }
`

