import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./clients.component.scss";

export const ClientsComponent = () => {

  return (
    <>
      <div className="clients-container">
        <div className="client-image-container">
          <StaticImage
            className="client-image"
            src="../../assets/images/cliente-1.png"
            alt=""
            placeholder="tracedSVG"
            
          />
        </div>

        <div className="client-image-container">
          <StaticImage
            className="client-image"
            src="../../assets/images/cliente-2.png"
            alt=""
            placeholder="tracedSVG"
          />
        </div>

        <div className="client-image-container">
          <StaticImage
            className="client-image"
            src="../../assets/images/cliente-3.png"
            alt=""
            placeholder="tracedSVG"
          />
        </div>

        <div className="client-image-container client-image-container-2">
          <StaticImage
            className="client-image image-1"
            src="../../assets/images/cliente-4.png"
            alt=""
            placeholder="tracedSVG"
          />
        </div>
      </div>
    </>
  );
};
